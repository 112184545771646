/* eslint-disable class-methods-use-this */
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { addMinutes, formatISO, parseISO } from 'date-fns';
import { isValid, nextQuarter } from '@/plugins/dates';
import axiosRestService from '@/network/axiosRestService';
import i18n from '@/i18n';
import ReservationDates from '@/model/reservationDates';
import Reservation from '@/model/reservation';
import Notification from '@/model/notification';
import ReservationProcessStep from '@/model/reservationProcessStep';
import Vue from 'vue';
import { DeviceType } from '@/model/deviceType';
import BaseModule from '../module';
import { RootState, State } from '../types';

/**
 * We need to pack this constant value inside a function, since otherwise, the Vue object would
 * not be defined in this scope.
 * @returns A number value representing the Audi minimum reservation duration in minutes
 */
const audiMinResDurationMins = (): number => Vue.prototype.$audiMinReservationDurationMins;
/**
 * We need to pack this constant value inside a function, since otherwise, the Vue object would
 * not be defined in this scope.
 * @returns A number value representing the Audi minimum prebook deadline in minutes
 */
const audiMinPrebookMins = (): number => Vue.prototype.$audiMinPrebookTimeMins;
const defaultSelectedDevices = ['PARKING_LOT', 'PARKING_LOT_WIRELESS', 'VIRTUAL'];
const defaultReservationData = () => ({
  loading: false,
  attributes: undefined,
  site: undefined,
  device: undefined,
  event: undefined,
  optionalDevices: {},
});

// #############################################
// #############  Helper Functions #############
// #############################################

/**
 * Determines the next valid reservation start time for an Audi reservation
 * @returns The date of the next valid reservation start time
 * for an Audi reservation, closest to now.
 */
function getNextPossibleAudiReservationStart(): Date {
  return nextQuarter(addMinutes(new Date(), 30));
}

/**
 * Takes the context and the body returned by the backend endpoint, and transforms the JSON API data
 * into a more convenient format.
 *
 * @param context The context of the store action
 * @param body The body returned by the API endpoint in JSON API format
 * @returns An object containing the locations, countries and sites in a more convenient format
 */
function prepareAccessibleSitesData(context: any, body: any) {
  const sites = body.data;
  const locations = body.included.filter((value: any) => value.type === 'locations');
  const countries: string[] = [];

  const sitesWithZones = sites.map(
    (site: { relationships: { zones: { data: Array<{ id: string }> } } }) => ({
      ...site,
      zones: site.relationships.zones.data.map((zone) => body.included.find(
        (included: { type: string; id: string }) => included.type === 'zones' && included.id === zone.id,
      )),
    }),
  );
  const sitesWithEvents = sitesWithZones.map(
    (site: { zones: Array<{ relationships: { events: { data: Array<{ id: string }> } } }> }) => ({
      ...site,
      zones: site.zones.map((zone) => ({
        ...zone,
        events: zone.relationships.events.data.map((event) => body.included.find(
          (included: { type: string; id: string }) => included.type === 'events' && included.id === event.id,
        )),
      })),
    }),
  );

  // We need to use this for loop, since we want to assign to the values
  for (let i = 0; i < locations.length; i += 1) {
    // Add the translated country name as attribute to the locations
    locations[i].attributes.countryName = context.rootGetters['countries/nameFromCountryCode'](
      locations[i]?.attributes?.country ?? '',
    );
    locations[i].attributes.countryTranslatedName = i18n.t(locations[i].attributes.countryName);

    // Create a list of unique country names from the locations
    if (!countries.includes(locations[i].attributes.countryName)) {
      countries.push(locations[i].attributes.countryName);
    }
  }

  locations.sort((a: any, b: any) => {
    // Sort two locations first by country name, then by city name
    if (a.attributes.country !== b.attributes.country) {
      return `${i18n.t(a.attributes.countryTranslatedName)}`.localeCompare(
        `${i18n.t(b.attributes.countryTranslatedName)}`,
      );
    }

    return `${a.attributes.city}`.localeCompare(`${b.attributes.city}`);
  });

  // We need a normal for-loop since we want to assign to the iterated element
  for (let i = 0; i < sitesWithEvents.length; i += 1) {
    sitesWithEvents[i].relationships.location = locations.find(
      (location: any) => location.id === sitesWithEvents[i].relationships.location.data.id,
    );
  }

  return {
    sites: sitesWithEvents,
    locations,
    countries,
  };
}

// #############################################
// ########  Reservation Process Store #########
// #############################################

interface ReservationProcessState extends State {
  preventNextReset: boolean;
  step: ReservationProcessStep;
  accessibleSites: {
    countries: string[];
    locations: object[];
    sites: object[];
    loading: boolean;
  };
  selection: {
    site: any | undefined;
    optionalDeviceTypes: DeviceType[];
    optionalDeviceTypesLoading: boolean;
    dates: ReservationDates;
    deviceTypes: string[];
    manualEnd: boolean;
  };
  // Data is undefined if no search has happened yet
  search: {
    loading: boolean;
    selectionReservable: boolean | undefined;
    alternative: boolean | ReservationDates | undefined;
    userIsBlocked: boolean | undefined;
    blockInformation:
      | {
          loading: boolean;
          data: any;
        }
      | undefined;
  };
  reservation: {
    loading: boolean;
    attributes: Reservation | undefined;
    site: any | undefined;
    device: any | undefined;
    event: any | undefined;
    optionalDevices: {
      // A map with keys named 'type' of type string and values with the defined object structure
      [type: string]: {
        loading: boolean;
        reservationId: number | undefined;
        unavailable: boolean;
      };
    };
  };
}

const defaultState = () => ({
  preventNextReset: false,
  step: ReservationProcessStep.FirstPage,
  accessibleSites: {
    countries: [],
    locations: [],
    sites: [],
    loading: false,
  },
  selection: {
    site: undefined,
    optionalDeviceTypes: [],
    optionalDeviceTypesLoading: false,
    dates: new ReservationDates(getNextPossibleAudiReservationStart(), audiMinResDurationMins()),
    deviceTypes: defaultSelectedDevices,
    manualEnd: false,
    events: [],
  },
  search: {
    loading: false,
    selectionReservable: undefined,
    alternative: undefined,
    userIsBlocked: undefined,
    blockInformation: undefined,
  },
  reservation: defaultReservationData(),
});

class ReservationProcessModule extends BaseModule<ReservationProcessState> {
  protected initialState(): ReservationProcessState {
    return defaultState();
  }

  protected buildGettersTree(): GetterTree<ReservationProcessState, RootState> {
    return {
      currentStep: (state) => state.step,
      isFirstPage: (state) => state.step === ReservationProcessStep.FirstPage,
      isMapPage: (state) => state.step === ReservationProcessStep.MapPage,
      isFinalizationPage: (state) => state.step === ReservationProcessStep.FinalizationPage,
      loadingAccessibleSites: (state) => state.accessibleSites.loading,
      accessibleSites: (state) => state.accessibleSites,
      selection: (state) => state.selection,
      selectedSite: (state) => state.selection.site,
      selectedSiteOptionalDeviceTypes: (state) => state.selection.optionalDeviceTypes,
      loadingOptionalDeviceTypes: (state) => state.selection.optionalDeviceTypesLoading,
      selectedSiteHasOptionalDevices: (state) => state.selection.optionalDeviceTypes.length > 0,
      selectedSiteLocation: (state) => state.selection.site.relationships.location,
      selectedDates: (state) => state.selection.dates,
      selectedStart: (state) => state.selection.dates.start,
      selectedEnd: (state) => state.selection.dates.end,
      isoStart: (state) => formatISO(state.selection.dates.start),
      isoEnd: (state) => formatISO(state.selection.dates.end),
      isoUtcStart: (state) => state.selection.dates.start.toISOString(),
      isoUtcEnd: (state) => state.selection.dates.end.toISOString(),

      selectionSufficient: (state) => state.selection.dates.sufficient()
        && state.selection.site !== undefined
        && state.selection.site.id !== undefined
        && state.selection.site.relationships.location !== undefined
        && state.selection.deviceTypes.length > 0,
      searchLoading: (state) => state.search.loading,
      searchData: (state) => state.search,
      reservationLoading: (state) => state.reservation.loading,
      reservationData: (state) => state.reservation,
      unavailableOptionalDeviceTypes: (state) => Object.entries(state.reservation.optionalDevices)
        .filter(([, value]) => value.unavailable)
        .map(([key]) => key),
      optionalDeviceTypeData: (state) => state.reservation.optionalDevices,
      selectedSiteAllowsManualReservationEnd: (state) => !!state.selection.site?.zones.find(
        (zone: { events: Array<{
          attributes: { maxReservationDuration: number }
        }> }) => !!zone.events.find(
          (event) => event.attributes.maxReservationDuration === -1,
        ),
      ),
    };
  }

  protected buildMutationsTree(): MutationTree<ReservationProcessState> {
    return {
      preventNextReset(state) {
        state.preventNextReset = true;
      },
      resetReservationProcess(state) {
        if (state.preventNextReset) {
          state.preventNextReset = false;
          return;
        }

        const defaults = defaultState();
        state.accessibleSites = defaults.accessibleSites;
        state.reservation = defaults.reservation;
        state.search = defaults.search;
        state.selection = defaults.selection;
        state.step = defaults.step;
      },
      updateStep(state, newStep: ReservationProcessStep) {
        if (newStep === ReservationProcessStep.MapPage) {
          state.reservation = defaultReservationData();
        }

        state.step = newStep;
      },
      updateLoadingAccessibleSites(state, value: boolean) {
        state.accessibleSites.loading = value;
      },
      updateAccessibleSites(state, data: any) {
        state.accessibleSites.countries = data.countries;
        state.accessibleSites.locations = data.locations;
        state.accessibleSites.sites = data.sites;
      },
      manualEnd(state, manualEnd: boolean) {
        state.selection.manualEnd = manualEnd;

        // We need to set the end time so that the reservation has a duration of 45 minutes if
        // manual end is deactivated
        if (!manualEnd) {
          state.selection.dates = new ReservationDates(
            state.selection.dates.start,
            audiMinResDurationMins(),
          );
        }
      },
      selectSite(state, id: number) {
        const foundSite = state.accessibleSites.sites.find((site: any) => site.id === id);
        if (state.accessibleSites.sites.length === 0 || foundSite === undefined) {
          console.error("Site could not be found, and therefore couldn't be selected");
        }
        state.selection.site = foundSite;
        state.selection.optionalDeviceTypes = [];
      },
      selectStart(state, date: Date) {
        let newStart = new Date(date);

        const earliestBookingPossible = addMinutes(new Date(), audiMinPrebookMins());

        if (newStart < earliestBookingPossible) {
          newStart = earliestBookingPossible;
        }

        // If the given date is not a quarter, get the next quarter.
        newStart = nextQuarter(newStart);

        // If the end is set manually, only change the end time if the new start time would lie
        // after the previously set end time
        if (state.selection.manualEnd && newStart < state.selection.dates.end) {
          state.selection.dates = new ReservationDates(newStart, state.selection.dates.end);
          return;
        }

        state.selection.dates = new ReservationDates(newStart, audiMinResDurationMins());
      },
      selectEnd(state, date: Date) {
        let newEnd = new Date(date);

        if (newEnd < state.selection.dates.start) {
          newEnd = addMinutes(state.selection.dates.start, audiMinResDurationMins());
        }

        newEnd = nextQuarter(newEnd);
        state.selection.dates.end = newEnd;
      },
      deselectSite(state) {
        state.selection = defaultState().selection;
      },
      selectDeviceTypes(state, types: string[]) {
        state.selection.deviceTypes = types;
      },
      resetSelection(state) {
        state.selection.site = undefined;
        state.selection.dates = new ReservationDates(
          getNextPossibleAudiReservationStart(),
          audiMinResDurationMins(),
        );
        state.selection.deviceTypes = defaultSelectedDevices;
      },
      setOptionalDeviceTypes(state, value: DeviceType[]) {
        state.selection.optionalDeviceTypes = value;
      },
      updateLoadingOptionalDeviceTypes(state, value: boolean) {
        state.selection.optionalDeviceTypesLoading = value;
      },
      updateSearchLoading(state, value: boolean) {
        state.search.loading = value;
      },
      updateSearchAlternative(state, alternative: ReservationDates | boolean) {
        state.search.alternative = alternative;
      },
      updateSearchUserIsBlocked(state, isBlocked: boolean) {
        state.search.userIsBlocked = isBlocked;
      },
      updateSearchSelectionReservable(state, reservable: boolean) {
        state.search.selectionReservable = reservable;
      },
      updateSearchLoadingBlockInformation(state, loading: boolean) {
        if (!state.search.blockInformation) {
          state.search.blockInformation = {
            loading,
            data: undefined,
          };
          return;
        }

        state.search.blockInformation.loading = loading;
      },
      updateSearchBlockInformationData(state, data: any) {
        if (!state.search.blockInformation) {
          state.search.blockInformation = {
            loading: false,
            data,
          };
          return;
        }

        state.search.blockInformation.data = data;
      },
      updateReservationLoading(state, loading: boolean) {
        state.reservation.loading = loading;
      },
      updateReservationAttributes(state, attributes: Reservation) {
        state.reservation.attributes = attributes;
      },
      updateReservationSite(state, site: any) {
        state.reservation.site = site;
      },
      updateReservationDevice(state, device: any) {
        state.reservation.device = device;
      },
      updateReservationEvent(state, event: any) {
        state.reservation.event = event;
      },
      updateOptionalReservation(
        state,
        data: {
          type: string;
          resId: number | undefined;
          loading: boolean;
          unavailable: boolean;
        },
      ) {
        state.reservation.optionalDevices[data.type] = {
          loading: data.loading,
          reservationId: data.resId,
          unavailable: data.unavailable,
        };
      },
      removeOptionalReservation(state, type: string) {
        const storedTypes = Object.keys(state.reservation.optionalDevices);
        if (storedTypes.includes(type)) {
          delete state.reservation.optionalDevices[type];
        }
      },
    };
  }

  protected buildActionsTree(): ActionTree<ReservationProcessState, RootState> {
    return {
      fetchAccessibleSites(context) {
        context.commit('updateLoadingAccessibleSites', true);

        axiosRestService
          .get('/sites/accessible?include=locations,zones.events', {
            headers: { Accept: 'application/vnd.api+json' },
          })
          .then((response) => {
            const preparedData = prepareAccessibleSitesData(context, response.data);
            context.commit('updateAccessibleSites', preparedData);
          })
          .finally(() => {
            context.commit('updateLoadingAccessibleSites', false);
          });
      },
      fetchOptionalDevicesForSite(context, siteId) {
        context.commit('updateLoadingOptionalDeviceTypes', true);

        axiosRestService
          .get<string[]>(`/sites/${siteId}/optional-device-types`)
          .then((response) => {
            const deviceTypesArray = response.data;

            // Jump to the 'finally statement' if the data doesn't contain any elements
            if (deviceTypesArray.length <= 0) {
              return Promise.resolve();
            }

            const optionalDeviceTypes: DeviceType[] = [];

            deviceTypesArray.forEach((type: string) => {
              optionalDeviceTypes.push(new DeviceType(type));
            });

            context.commit('setOptionalDeviceTypes', optionalDeviceTypes);
            return Promise.resolve();
          })
          .finally(() => {
            context.commit('updateLoadingOptionalDeviceTypes', false);
          });
      },
      search(context) {
        if (!context.getters.selectionSufficient) {
          return;
        }
        const url = `/sites/${context.state.selection.site.id}/reservable`;

        const start = context.getters.isoUtcStart;
        const end = context.getters.isoUtcEnd;
        const queryParams = [
          `start=${start}`,
          `end=${end}`,
          'type=PARKING_LOT,PARKING_LOT_WIRELESS,VIRTUAL',
        ];

        context.commit('updateSearchLoading', true);

        axiosRestService
          .get(`${url}?${queryParams.join('&')}`, {
            headers: { Accept: 'application/vnd.api+json' },
          })
          .then(() => {
            // The only positive response we get from the backend is
            // that the selection is reservable
            context.commit('updateSearchSelectionReservable', true);
            context.commit('updateSearchUserIsBlocked', false);
            context.commit('updateSearchAlternative', false);
          })
          .catch((error) => {
            const body = error.response.data;

            // Regardless of the error, the selection can't be reserved.
            context.commit('updateSearchSelectionReservable', false);

            // Set these to the default value, which gets overridden if information is available.
            context.commit('updateSearchUserIsBlocked', false);
            context.commit('updateSearchAlternative', false);

            switch (parseInt(body.errors[0].status, 10)) {
              case 409:
                // If the body contains meta, it is a suggested alternative
                if (body.errors[0].meta) {
                  const { alternative } = body.errors[0].meta;
                  context.commit(
                    'updateSearchAlternative',
                    new ReservationDates(parseISO(alternative.start), parseISO(alternative.end)),
                  );
                }
                break;
              case 423:
                context.commit('updateSearchUserIsBlocked', true);
                context.dispatch('getBlockInformation');
                break;
              default:
                break;
            }
          })
          .finally(() => {
            context.commit('updateSearchLoading', false);
          });
      },
      populateOptionalDeviceTypes(context) {
        // Go through each unique optional device type and add populate the reservation type map
        context.getters.selectedSiteOptionalDeviceTypes.forEach((type: DeviceType) => {
          context.commit('updateOptionalReservation', {
            type: type.originType,
            resId: undefined,
            loading: false,
            unavailable: false,
            color: 'black',
          });
        });
      },
      getBlockInformation(context) {
        context.commit('updateSearchLoadingBlockInformation', true);

        axiosRestService
          .get<any>(`/sites/${context.state.selection.site.id}/block-information`, {
            headers: {
              Accept: 'application/vnd.api+json',
            },
          })
          .then((response) => {
            context.commit('updateSearchBlockInformationData', response.data.data);
          })
          .catch((error) => {
            if ((error?.response?.data?.errors[0]?.errorCode ?? -1) === 2) {
              context.dispatch(
                'notifications/error',
                new Notification(
                  `${i18n.t('Seems like an error occurred, please reload the page')}`,
                ),
                { root: true },
              );
              return Promise.resolve();
            }

            context.dispatch(
              'notifications/error',
              new Notification(`${i18n.t('Block information could not be loaded.')}`),
              { root: true },
            );
            return Promise.resolve();
          })
          .finally(() => {
            context.commit('updateSearchLoadingBlockInformation', false);
          });
      },
      async checkOptionalDeviceAvailability(context, type: string) {
        if (context.getters.unavailableOptionalDeviceTypes.includes(type)) {
          // The type is already known to be unavailable, so don't even try it
          return;
        }

        context.commit('updateOptionalReservation', {
          type,
          resId: undefined,
          loading: true,
          unavailable: false,
        });

        const createdReservation = context.state.reservation.attributes;
        const siteId = context.state.reservation.site.id;
        if (createdReservation === undefined) {
          return;
        }

        const attributes = {
          start: createdReservation.dates.start,
          end: createdReservation.dates.end,
          deviceTypes: [type],
        };
        const site = {
          data: {
            type: 'sites',
            id: `${siteId}`,
          },
        };
        const parentReservation = {
          data: {
            type: 'reservations',
            id: `${createdReservation.id}`,
          },
        };
        await axiosRestService
          .post<any>(
            '/reservations',
            {
              data: {
                type: 'reservations',
                attributes,
                relationships: {
                  site,
                  parentReservation,
                },
              },
            },
            {
              headers: {
                Accept: 'application/vnd.api+json',
                'Content-Type': 'application/vnd.api+json',
              },
            },
          )
          .then((response) => {
            context.commit('updateOptionalReservation', {
              type,
              resId: parseInt(response.data.data.id, 10),
              loading: false,
              unavailable: false,
            });
          })
          .catch((error) => {
            context.commit('updateOptionalReservation', {
              type,
              resId: undefined,
              loading: false,
              unavailable: true,
            });
          });
      },
      async cancelOptionalDeviceReservation(context, type: string) {
        const optionalDeviceAttributes = context.state.reservation.optionalDevices[type];

        const resIdToRemove = optionalDeviceAttributes.reservationId;

        // If the reservation has no resId, don't try it since it would cause an error
        if (resIdToRemove === undefined) {
          return;
        }

        context.commit('updateOptionalReservation', {
          type,
          resId: resIdToRemove,
          loading: true,
          unavailable: optionalDeviceAttributes.unavailable,
        });

        await axiosRestService
          .delete(`/reservations/${resIdToRemove}`, {
            headers: {
              Accept: 'application/vnd.api+json',
            },
          })
          .finally(() => {
            // We don't care if the cancelling worked, we don't want this res to be finalized
            context.commit('updateOptionalReservation', {
              type,
              resId: undefined,
              loading: false,
              unavailable: false,
            });
          });
      },
      book(context) {
        context.commit('updateReservationLoading', true);

        let { dates } = context.state.selection;

        if (
          !context.state.search.selectionReservable
          && context.state.search.alternative instanceof ReservationDates
        ) {
          dates = context.state.search.alternative;
        }

        if (!isValid(dates.start) || !isValid(dates.end)) {
          context.dispatch(
            'notifications/error',
            new Notification(`${i18n.t('Reservation could not be created')}`),
            { root: true },
          );
          context.commit('updateReservationLoading', false);
          return;
        }

        const payload = {
          data: {
            type: 'reservation',
            attributes: {
              start: dates.start,
              end: dates.end,
              deviceTypes: context.state.selection.deviceTypes,
            },
            relationships: {
              site: {
                data: {
                  type: 'sites',
                  id: `${context.state.selection.site.id}`,
                },
              },
            },
          },
        };

        // Fill the optional device types array
        context.dispatch('populateOptionalDeviceTypes');

        axiosRestService
          .post<any>('/reservations', payload, {
            headers: {
              Accept: 'application/vnd.api+json',
              'Content-Type': 'application/vnd.api+json',
            },
            params: {
              include: 'device,devices.site,site,user,event',
            },
          })
          .then((response) => {
            const body = response.data;

            // If the created reservation suddenly is at another site than the site that was
            // selected in the first step, something went wrong.
            if (body.data.relationships.site.data.id !== context.state.selection.site.id) {
              return Promise.reject();
            }

            // Get the device and site data from the response
            const { site } = context.state.selection;
            const device = body.included?.find(
              (el: any) => el.type === 'devices' && el.id === body.data.relationships.device.data.id,
            ) ?? undefined;
            const event = body.included?.find(
              (el: any) => el.type === 'events' && el.id === body.data.relationships.event.data.id,
            ) ?? undefined;

            // Create new reservation attributes with the data from the response
            const respAttrs = body.data.attributes;
            const attributes = new Reservation(
              body.data.id,
              new ReservationDates(parseISO(respAttrs.start), parseISO(respAttrs.end)),
              respAttrs.reservationState,
              respAttrs.price,
              respAttrs.contracts,
            );

            context.commit('updateReservationAttributes', attributes);
            context.commit('updateReservationSite', site);
            context.commit('updateReservationDevice', device);
            context.commit('updateReservationEvent', event);
            context.commit('updateStep', ReservationProcessStep.FinalizationPage);
            return Promise.resolve();
          })
          .catch((_) => {
            context.dispatch(
              'notifications/error',
              new Notification(`${i18n.t('Reservation could not be created')}`),
              { root: true },
            );
          })
          .finally(() => {
            context.commit('updateReservationLoading', false);
          });
      },
      getMinimumSelectableTime(context) {
        return getNextPossibleAudiReservationStart();
      },
    };
  }
}

export default new ReservationProcessModule(true);
