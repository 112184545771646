/* eslint-disable class-methods-use-this */
import Axios, { AxiosRequestConfig } from 'axios';
import RestService from './restService';
import Response from './response';

class AxiosRestService implements RestService {
  private unauthorizedResponseHandlers: Array<() => void> = [];

  private forbiddenResponseHandlers: Array<() => void> = [];

  private responseErrorHandlers: Array<() => void> = [];

  // eslint-disable-next-line no-useless-constructor
  constructor() {
    Axios.defaults.headers.common = {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'X-Client-Id': process.env.VUE_APP_API_CLIENT_ID ?? '',
    };

    Axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
    Axios.defaults.withCredentials = true;

    Axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (!navigator.onLine) {
          // client is offline, don't display error messages
          return Promise.reject(error);
        }

        if (!error.response || !error.response.status) {
          this.responseErrorHandlers.forEach((handler) => handler());
          return Promise.reject(error);
        }

        switch (error.response.status) {
          case 401:
            this.unauthorizedResponseHandlers.forEach((handler) => handler());
            break;
          case 403:
            this.forbiddenResponseHandlers.forEach((handler) => handler());
            break;
          case 500:
            this.responseErrorHandlers.forEach((handler) => handler());
            break;
          default:
            break;
        }
        return Promise.reject(error);
      },
    );
  }

  addUnauthorizedResponseHandler(handler: () => void): void {
    this.unauthorizedResponseHandlers.push(handler);
  }

  addForbiddenResponseHandler(handler: () => void): void {
    this.forbiddenResponseHandlers.push(handler);
  }

  addResponseErrorHandler(handler: () => void): void {
    this.responseErrorHandlers.push(handler);
  }

  setAcceptedLanguage(language: string): void {
    Axios.defaults.headers.common['Accept-Language'] = language;
  }

  setBearerToken(token: string): void {
    Axios.defaults.headers.common.Authorization = `bearer ${token}`;
  }

  setAuthorizationHeader(value: string): void {
    Axios.defaults.headers.common.Authorization = value;
  }

  unsetAuthorizationHeader(): void {
    delete Axios.defaults.headers.common.Authorization;
  }

  get<T>(url: string, config?: AxiosRequestConfig<any>): Promise<Response<T>> {
    return Axios.get(url, config);
  }

  post<T>(url: string, data: unknown, config?: AxiosRequestConfig<any>): Promise<Response<T>> {
    return Axios.post(url, data, config);
  }

  patch<T>(url: string, data: unknown, config?: AxiosRequestConfig<any>): Promise<Response<T>> {
    return Axios.patch(url, data, config);
  }

  put<T>(url: string, data: unknown, config?: AxiosRequestConfig<any>): Promise<Response<T>> {
    return Axios.put(url, data, config);
  }

  delete<T>(url: string, config?: AxiosRequestConfig<any>): Promise<Response<T>> {
    return Axios.delete(url, config);
  }
}

export default new AxiosRestService() as RestService;
